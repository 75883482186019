import React, { useState, useRef } from "react";
import styles from "./StepByStep.module.scss"
import { useParallax } from "react-scroll-parallax";

const StepByStep = () => {
    const [gifKey, setGifKey] = useState(0);
    const gifContainerRef = useRef();

    const parallax = useParallax({
        onEnter: (e) => {
            handleRestartGif()
        },
        onExit: (e) => {
        },
        onProgressChange: (e) => {
        }
    });

    const handleRestartGif = () => {
        const element = document.getElementById("gif-howto");
        element.src = " "
        element.src = "https://byreplica.io/assets/gifs/howto.gif"
    }

    return (
        <div ref={parallax.ref} className={styles.Section}>
            <div className={styles.SectionSticky}>
                <div className={styles.GifContainer} ref={gifContainerRef}>
                    <img id="gif-howto" key={gifKey} src="https://byreplica.io/assets/gifs/howto.gif" alt="Macbook showing screenshots from platform" />
                </div>
            </div>
        </div>
    )
}

export default StepByStep;