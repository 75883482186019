import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./About.module.scss";
import { ReactComponent as RIcon } from '../../icons/r-vector.svg';
import { ReactComponent as RLogo } from '../../icons/replica-logo.svg';
import { Parallax } from 'react-scroll-parallax';
import { LanguageContext } from '../../context/LanguageContext.jsx';
import content from './content.json';

export default function About() {
  const { language } = useContext(LanguageContext);
  const parentRef = useRef(null);
  const [startScroll, setStartScroll] = useState(0);

  useEffect(() => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      const scrollTop = window.scrollY;
      setStartScroll(rect.top + scrollTop);
    }
  }, []);

  return (
    <div ref={parentRef} className={styles.About}>
      <div className={styles.AboutWrapper}>
        <Parallax translateX={[-10, 0]} startScroll={startScroll}
          endScroll={startScroll + window.innerHeight}>
          <div className={styles.SquareImg}>
            <img className={styles.SquareAd} src="/assets/images/digital-ad-moda.gif" alt="Representação de uma peça digital" />
            <img className={styles.SquareBaloon} src={content.balloon[language]} alt="98% de optimização de tempo" />
          </div>
        </Parallax>

        <div className={styles.SquareContainer}>
          <RLogo className={styles.ReplicaLogo} />
          <p className={styles.AboutText}>
            {content.text[language]}
          </p>
        </div>
        <Parallax startScroll={startScroll}
          endScroll={startScroll + window.innerHeight} className={styles.ReplicaIcon} translateX={[40, -20]}>
          <RIcon className={styles.ReplicaIconSVG} />
        </Parallax>
      </div>
    </div>
  );
}
