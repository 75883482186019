import React, { useState, useRef } from 'react';
import styles from "../../sections/AboutVideo/AboutVideo.module.scss";

const VideoPlayer = ({ src, ...props }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    return (
        <div {...props}>
            <video
                preload='auto'
                poster="\assets\images\capa-video.png"
                ref={videoRef}
                onClick={isPlaying ? handlePause : handlePlay}
                src={src}
                style={{ cursor: 'pointer' }}
            ></video>
            {!isPlaying && (
                <div
                    className={styles.playButton}
                    onClick={handlePlay}
                >
                    <div>▶</div>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
